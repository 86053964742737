import { createEffect, createEvent } from "effector";
import t from "i18next";
import { createEffectApi, useCreateMutation } from "../../../shared/lib";
import { checkPopupViewed, getPopups } from "../api/popup.api";
import { IGetPopups } from "../api/popup.dto";

export const openPopupModalEvent = createEvent<boolean>();

export const LoginEvent = createEvent<void>();

export const getPopupsFx = createEffectApi(async (params: IGetPopups) => {
  const result = await getPopups(params);
  return result.data;
});

export const checkPopupViewedFx = createEffectApi(async (id: number) => {
  const result = await checkPopupViewed(id);
  return result.data;
});

export const getUnviewedPopupsFx = createEffect(async () => {
  const popups = await getPopupsFx({
    page: 1,
    size: 1000,
    isViewed: false,
    lang: t.language,
    order: "createdAt",
    order_type: -1,
  });
  return popups;
});

export const checkIfThereAreUnviewedPopupsFx = createEffect(async () => {
  const result = await getPopups({
    page: 1,
    size: 1,
    isViewed: false,
    lang: t.language,
    order: "createdAt",
    order_type: -1,
  });

  return result.data.rows.length > 0;
});

export const useGetPopups = () => useCreateMutation(getPopupsFx);
export const useCheckPopupsViewed = () => useCreateMutation(checkPopupViewedFx);
export const useCheckAnyPopupIsUnviewed = () =>
  useCreateMutation(checkIfThereAreUnviewedPopupsFx);
