// noinspection AllyPlainJsInspection

import { ITheme } from "./ITheme";

const GoodwinLightTheme: ITheme = {
  palette: {
    base: {
      main: "#2269C2",
      secondary: "#76B0FA",
      dop: "#2269C2",
      white: "#FFFFFF",
      black: "#000000",
    },
    color: {
      main: "#000000",
      secondary_1: "#000000",
      secondary_2: "#FFFFFF",
      secondary_3: "#000000",
      link: "#2269C2",
      placeholder: "#9B9EAD",
      active_1: "#2269C2",
      active_2: "#FFFFFF",
      disabled: "#A8A8A8",
      messages_title: "#D9D9D9",
      messages_title_unread: "#000000",
      messages_description: "#D9D9D9",
      messages_description_unread: "#626262",
    },
    background: {
      main_background: "#FFFFFF",
      main_image_path: "assets/images/desktop_background_icon.svg",
      main_content: "#FFFFFF",
      secondary_1: "#FFFFFF",
      secondary_2: "#FFFFFF",
      modal: "#FFFFFF",
      loader: "#464646",
      language: "#464646",
      mobile_title: "#CBCBCB",
      active_1: "#2269C2",
      active_2: "#2269C2",
      disabled: "#252525",
      message: "#FFFFFF",
    },
    border: {
      main: "#434550",
      secondary: "#5E5E5E",
      dop: "#464646",
      variant_1: "none",
      modal: "none",
      message: "1px solid #434550",
      message_hovered: "border: 1px solid black",
    },
    border_radius: {
      modal: "8px",
      message: "8px",
    },
    margins: {
      modal: {
        title: "none",
      },
    },
    paddings: {
      modal: {
        popup: "40px",
      },
    },
    shadow: {
      modal: "0px 4px 14px " + "#00000019",
      footer: "0px 0px 30px " + "#00000040",
      drawer: "0px 4px 4px " + "#00000019",
      game_card: "0 4px 15px" + "#65AAFF33",
      content: "0 0 84px " + "#65AAFF99",
      language: "0 4px 4px " + "#0000003F",
      variant_1: "-2px 0 10px " + "#0000003f" + ", 4px 4px 10px " + "#0000003f",
      text_1: "0 0 9px " + "#0A2D91",
    },
    svg: {
      main: "#000000",
      secondary: "#184885",
      dop: "#4396FF",
      cross_icon: "#626262",
    },
    font_size: {
      modal: {
        header: "24px",
      },
    },
    header: {
      desktop: {
        menu_color: "#464646",
        divide_color: "#5E5E5E",
        first_background: "#323232",
        second_background: "#FFFFFF",
        menu_hover_background: "#C8C8C8CC",
      },
      mobile: {
        background: "#323232",
      },
    },
    footer: {
      desktop: {
        behind: "#464646",
        front: "#464646",
      },
      mobile: {
        main: "#323232",
        color: "#FFFFFF",
        active: "#2269C2",
        active_color: "#FFFFFF",
        active_line: "#76B0FA",
      },
    },
    button: {
      primary: {
        color: "#FFFFFF",
        background: "#2269C2",
        border: "#2269C2",
        hover_color: "#FFFFFF",
        hover_border: "#76B0FA",
        hover_background: "#2269C2",
        disabled_color: "#FFFFFF",
        disabled_border: "#76B0FA",
        disabled_background: "#2269C2",
      },
      secondary: {
        color: "#FFFFFF",
        background: "#2269C2",
        border: "#2269C2",
        hover_color: "#FFFFFF",
        hover_border: "#76B0FA",
        hover_background: "#2269C2",
        disabled_color: "#FFFFFF",
        disabled_border: "#76B0FA",
        disabled_background: "#2269C2",
      },
      simple: {
        color: "#FFFFFF",
        background: "#5E5E5E",
        border: "#76B0FA",
        hover_color: "#5E5E5E",
        hover_border: "#76B0FA",
        hover_background: "#76B0FA",
        disabled_color: "#5E5E5E",
        disabled_border: "#76B0FA",
        disabled_background: "#76B0FA",
      },
    },
    game: {
      mobile_title: {
        color: "#000000",
        border: "#D9D9D9",
        background: "#D9D9D9",
        active_color: "#FFFFFF",
        active_border: "#2269C2",
        active_background: "#2269C2",
      },
      card: {
        title_background: "#2269C2",
        title_color: "#FFFFFF",
        favorite_color: "#2269C2",
        favorite_hover_color: "#FCC520",
        favorite_active_color: "#FFFFFF",
      },
      main_page: {
        tv_game_title_background_image_path:
          "assets/images/main/main_tv_games_light_footer_icon.svg",
        tv_game_title_background_color: "#181818",
        card_border: "#D4E9F4",
        link_color: "#076685E5",
      },
      other: {
        accordion_footer_gradient:
          "linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgb(255,255,255) 40%, rgb(255, 255, 255) 100%)",
      },
    },
    mobile_profile_menu: {
      odd_color: "#000000",
      even_color: "#000000",
      svg_odd_color: "#499C23",
      svg_even_color: "#2269C2",
      background: "#F6F6F6",
      cell_background: "#FFFFFF",
      cell_border: "1px solid " + "#434550",
      title_svg_color: "#184885",
    },
    jackpot: {
      desktop_text: "#FFFFFF",
      mobile_text: "#000000",
      desktop_numbers: "#4396FF",
      mobile_numbers: "#2269C2",
    },
    theme_toggle: {
      border: "#434550",
      background: "#FFFFFF",
      toggle_background: "#252525",
      icon_color: "#FFFFFF",
    },
    date_picker: {
      color: "#000000",
      background: "#eaeaea",
      hover_background: "#b0b0b0",
      active_background: "#2269C2",
      arrow_icon_path: "assets/images/desktop_menu_down_black_arrow_icon.svg",
    },
    hall_of_fame: {
      text_color: "#181818",
      secondary_text_color: "#79531F",
      third_text_color: "#906325",
      main_color: "#4D2608",
      secondary_color: "#181818",
      border_color: "#724E1D",
      background_color: "#A1712B",
      secondary_background_color: "#C89D4B",
      background_hover: "#FFF6B1",
      background: "assets/images/hall_of_flame/bg_Def.png",
      background_image: "assets/images/hall_of_flame/bg_Item_Gl.png",
      background_image_mobile:
        "assets/images/hall_of_flame/bg_Item_Def_mobile.png",
      box_shadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
    },
    high_roller: {
      text_color: "#76B0FA",
      secondary_text_color: "#F3EBA8",
      svg_color: "#434550",
      border_color: "#2F88F8",
      background_color: "#000000",
      secondary_background_color: "#060912",
      background_image: "assets/images/footer/high_roller_bg.svg",
      button_open: "assets/images/footer/high_roller_arrow.svg",
      button_close: "assets/images/footer/high_roller_close.svg",
    },
    success: {
      primary: "#03D771",
      secondary: "#27AE60",
    },
    error: {
      primary: "#C01414",
      secondary: "#C01414",
    },
    alert: {
      success: "#03D771",
      warning: "#F8B500",
      error: "#C01414",
    },
    clear_cache:{
      background: "#2A183F",
      text:"#FFFFFF",
      button_text: '#C8102E'
    }
  },
  size: {
    games: {
      desktop: {
        column: 6,
        row: 3,
        height_img: 118,
        height_title: 32,
        width: 162,
        row_gap: 15,
        column_gap: 14,
      },
      mobile: {
        column: 3,
        row: 4,
        height_img: -1,
        height_title: 35,
        width: -1,
        row_gap: 7,
        column_gap: 7,
      },
    },
    tv_games: {
      desktop: {
        column: 4,
        row: 2,
        height_img: 160,
        height_title: 43,
        width: 245,
        row_gap: 15,
        column_gap: 22,
      },
      mobile: {
        column: 2,
        row: 3,
        height_img: 90,
        height_title: 40,
        width: -1,
        row_gap: 7,
        column_gap: 8,
      },
    },
  },
};

export default GoodwinLightTheme;
