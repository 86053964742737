// noinspection AllyPlainJsInspection

import { ITheme } from "./ITheme";

const CrazywinDarkTheme: ITheme = {
  palette: {
    base: {
      main: "#613C93",
      secondary: "#B794E7",
      dop: "#B794E7",
      white: "#FFFFFF",
      black: "#000000",
    },
    color: {
      main: "#FFFFFF",
      secondary_1: "#9B9EAD",
      secondary_2: "#9B9EAD",
      secondary_3: "#FFFFFF",
      link: "#613C93",
      placeholder: "#9B9EAD",
      active_1: "#B794E7",
      active_2: "#FFFFFF",
      disabled: "#A8A8A8",
      messages_title: "#828282",
      messages_title_unread: "#FFFFFF",
      messages_description: "#828282",
      messages_description_unread: "#FFFFFF",
    },
    background: {
      main_background: "#252525",
      main_image_path:
        "assets/images/crazywin_desktop_dark_background_icon.svg",
      main_content: "#252525",
      secondary_1: "#131313",
      secondary_2: "#181818",
      modal: "#252525",
      loader: "#2A183F",
      language: "#252525",
      mobile_title: "#323232",
      active_1: "#613C93",
      active_2: "#613C93",
      disabled: "#252525",
      message: "#000000",
    },
    border: {
      main: "#B794E7",
      secondary: "#B794E7",
      dop: "#9B9EAD",
      provider: "1px solid" + "#B794E7",
      variant_1: "1px solid" + "#B794E7",
      modal: "2px solid" + "#B794E7",
      message: "1px solid #000000",
      message_hovered: "1px solid #B794E7",
    },
    border_radius: {
      modal: "0",
      message: "0",
    },
    margins: {
      modal: {
        title: "35px 0 0 0",
      },
    },
    paddings: {
      modal: {
        popup: "none",
      },
    },
    shadow: {
      modal: "0px 4px 84px" + "#CEAAFF",
      footer: "0px 4px 4px" + "#00000087",
      drawer: "0px 4px 84px " + "#CBA5FF7C",
      game_card: "",
      content: "0px 4px 84px" + "#CEAAFF",
      language: "0px 2px 10px" + "#B794E782",
      variant_1: "",
      text_1: "0px 2px 10px" + "#B794E782",
      filter_games: "0px 2px 10px" + "#B794E782",
    },
    svg: {
      main: "#A1A1A1",
      secondary: "#613C93",
      dop: "#B794E7",
      cross_icon: "#828282",
    },
    font_size: {
      modal: {
        header: "24px",
      },
    },
    header: {
      desktop: {
        menu_color: "#FFFFFF",
        divide_color: "#5E5E5E",
        first_background: "#262626",
        second_background: "#131313",
        menu_hover_background: "#191919",
      },
      mobile: {
        background: "#131313",
      },
    },
    footer: {
      desktop: {
        behind: "#181818",
        front: "#1F1F1F",
      },
      mobile: {
        main: "#131313",
        color: "#FFFFFF",
        active: "#613C93",
        active_color: "#FFFFFF",
        active_line: "#613C93",
      },
    },
    button: {
      primary: {
        color: "#FFFFFF",
        background: "#613C93",
        border: "#613C93",
        hover_color: "#FFFFFF",
        hover_border: "#B794E7",
        hover_shadow: "0px 2px 10px" + "#B794E782",
        hover_background: "#613C93",
        disabled_color: "#FFFFFF",
        disabled_border: "#5E5E5E",
        disabled_background: "#5E5E5E",
      },
      secondary: {
        color: "#FFFFFF",
        background: "#613C93",
        border: "#613C93",
        hover_color: "#FFFFFF",
        hover_border: "#B794E7",
        hover_shadow: "0px 2px 10px" + "#B794E782",
        hover_background: "#613C93",
        disabled_color: "#FFFFFF",
        disabled_border: "#5E5E5E",
        disabled_background: "#5E5E5E",
      },
      simple: {
        color: "#FFFFFF",
        background: "#5E5E5E",
        border: "#5E5E5E",
        hover_color: "#5E5E5E",
        hover_border: "#9B9EAD",
        hover_background: "#9B9EAD",
        disabled_color: "#9B9EAD",
        disabled_border: "#5E5E5E",
        disabled_background: "#5E5E5E",
      },
    },
    game: {
      mobile_title: {
        color: "#FFFFFF",
        border: "#613C93",
        background: "#252525",
        active_color: "#FFFFFF",
        active_border: "#B794E7",
        active_background: "#613C93",
      },
      card: {
        title_background: "#613C93",
        title_color: "#FFFFFF",
        favorite_color: "#613C93",
        favorite_hover_color: "#FCC520",
        favorite_active_color: "#FFFFFF",
      },
      main_page: {
        tv_game_title_background_image_path:
          "assets/images/main/main_tv_games_dark_footer_icon.svg",
        tv_game_title_background_color: "#613C93",
        card_border: "#613C93",
        link_color: "#B794E7",
      },
      other: {
        accordion_footer_gradient:
          "linear-gradient(180deg, rgba(37,37,37,0.5) 0%, rgb(37,37,37) 40%, rgb(37, 37, 37) 100%)",
      },
    },
    mobile_profile_menu: {
      odd_color: "#FFFFFF",
      even_color: "#FFFFFF",
      svg_odd_color: "#613C93",
      svg_even_color: "#613C93",
      background: "#181818",
      cell_background: "#252525",
      cell_border: "1px solid " + "#B794E7",
      title_svg_color: "#613C93",
    },
    jackpot: {
      desktop_text: "#FFFFFF",
      mobile_text: "#FFFFFF",
      desktop_numbers: "#B794E7",
      mobile_numbers: "#B794E7",
    },
    theme_toggle: {
      border: "#323232",
      background: "#323232",
      toggle_background: "#131313",
      icon_color: "#FFFFFF",
    },
    date_picker: {
      color: "#000000",
      background: "#eaeaea",
      hover_background: "#b0b0b0",
      active_background: "#613C93",
      arrow_icon_path: "assets/images/desktop_menu_down_white_arrow_icon.svg",
    },
    hall_of_fame: {
      text_color: "#FFFFFF",
      secondary_text_color: "#79531F",
      third_text_color: "#906325",
      main_color: "#4D2608",
      secondary_color: "#181818",
      border_color: "#724E1D",
      background_color: "#A1712B",
      secondary_background_color: "#C89D4B",
      background_hover: "#FFF6B1",
      background: "assets/images/hall_of_flame/bg_CrazyWin.png",
      background_image: "assets/images/hall_of_flame/bg_Item_CrW.png",
      background_image_mobile:
        "assets/images/hall_of_flame/bg_Item_CrW_mobile.png",
      box_shadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
    },
    high_roller: {
      text_color: "#B794E7",
      secondary_text_color: "#F3EBA8",
      svg_color: "#434550",
      border_color: "#2A183F",
      background_color: "#000000",
      secondary_background_color: "#060912",
      background_image: "assets/images/footer/high_roller_bg.svg",
      button_open: "assets/images/footer/high_roller_arrow.svg",
      button_close: "assets/images/footer/high_roller_close.svg",
    },
    success: {
      primary: "#03D771",
      secondary: "#27AE60",
    },
    error: {
      primary: "#C01414",
      secondary: "#C01414",
    },
    alert: {
      success: "#03D771",
      warning: "#F8B500",
      error: "#C01414",
    },
    clear_cache:{
      background: "#2A183F",
      text:"#FFFFFF",
      button_text: '#FFFFFF'
    }
  },
  size: {
    games: {
      desktop: {
        column: 6,
        row: 3,
        height_img: 118,
        height_title: 32,
        width: 162,
        row_gap: 15,
        column_gap: 14,
      },
      mobile: {
        column: 3,
        row: 4,
        height_img: -1,
        height_title: 35,
        width: -1,
        row_gap: 7,
        column_gap: 7,
      },
    },
    tv_games: {
      desktop: {
        column: 4,
        row: 2,
        height_img: 160,
        height_title: 43,
        width: 245,
        row_gap: 15,
        column_gap: 22,
      },
      mobile: {
        column: 2,
        row: 3,
        height_img: 90,
        height_title: 40,
        width: -1,
        row_gap: 7,
        column_gap: 8,
      },
    },
  },
};

export default CrazywinDarkTheme;
