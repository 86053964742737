import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAlert, IAppState} from "../types/appTypes";
import {appAPI} from "../api/appApi";

const initialState: IAppState = {
    pages: [],
    page: null,
    theme_type: 'light',
    modalRegistration: false,
    modalLogin: false,
    modalResetPassword: false,
    modalCheckPhone: false,
    modalCheckEmail: false,
    modalPromotion: false,
    modalPopup: false,
    modalConfirm: false,
    modalProviders: false,
    modal: false,
    alert: {
        status: "error",
        message: "",
        isOpen: false
    },
    isMobile: false,
    isLoading: false,
    languages: [],
    betby: null
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setTheme(state, action) {
            state.theme_type = action.payload
        },
        toggleTheme(state) {
            state.theme_type = state.theme_type === 'light' ? 'dark' : 'light'
        },
        updateModalRegistration(state) {
            state.modalRegistration = !state.modalRegistration
            state.modal = state.modalRegistration
        },
        updateModalLogin(state) {
            state.modalLogin = !state.modalLogin
            state.modal = state.modalLogin
        },
        updateModalResetPassword(state) {
            state.modalResetPassword = !state.modalResetPassword
            state.modal = state.modalResetPassword
        },
        updateModalCheckPhone(state) {
            state.modalCheckPhone = !state.modalCheckPhone
            state.modal = state.modalCheckPhone
        },
        updateModalCheckEmail(state) {
            state.modalCheckEmail = !state.modalCheckEmail
            state.modal = state.modalCheckEmail
        },
        updateModalPromotion(state) {
            state.modalPromotion = !state.modalPromotion
            state.modal = state.modalPromotion
        },
        updateModalConfirm(state) {
            state.modalConfirm = !state.modalConfirm
            state.modal = state.modalConfirm
        },
        updateModalProviders(state) {
            state.modalProviders = !state.modalProviders
            state.modal = state.modalProviders
        },
        updateModalPopup(state) {
            state.modalPopup = !state.modalPopup
            state.modal = state.modalPopup
        },
        updateModal(state) {
            state.modal = !state.modal
        },
        closeAllModals(state) {
            state.modalRegistration = false
            state.modalLogin = false
            state.modalResetPassword = false
            state.modalCheckPhone = false
            state.modalPromotion = false
            state.modalConfirm = false
            state.modal = false
        },
        updateAlert(state, action: PayloadAction<IAlert>) {
            state.alert = action.payload
        },
        isMobileTrue(state) {
            state.isMobile = true
        },
        updateIsLoading(state, {payload}) {
            state.isLoading = payload
        },
        setBetbyEvent(state, {payload}){
            state.betby = payload
        }
    },
    extraReducers: (builder => {
        builder
            .addMatcher(
                appAPI.endpoints.getStaticPages.matchFulfilled,
                (state, {payload}) => {
                    state.pages = payload
                })
            .addMatcher(
                appAPI.endpoints.getStaticPage.matchFulfilled,
                (state, {payload}) => {
                    state.page = payload
                })
            .addMatcher(
                appAPI.endpoints.getLanguages.matchFulfilled,
                (state, {payload}) => {
                    // console.log('reducer getLanguages = ', payload)
                    state.languages = payload
                })
    })
})

export const {
    updateModalRegistration,
    updateModalLogin,
    closeAllModals,
    updateAlert,
    updateModal,
    updateModalCheckPhone,
    updateModalResetPassword,
    isMobileTrue,
    updateModalPromotion,
    updateIsLoading,
    updateModalConfirm,
    updateModalProviders,
    updateModalCheckEmail,
    updateModalPopup,
    setTheme,
    toggleTheme,
    setBetbyEvent
} = appSlice.actions

export default appSlice.reducer

