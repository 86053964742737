import React, {useEffect, useRef} from 'react';
import Portal from "services/PortalService";
import useOutsideClick from "hooks/useOutsideClick";
import styled, {css} from "styled-components";
import {useTypedSelector} from "hooks/useTypedRedux";
import {selectModal} from "redux/selectors/modalSelector";

type ModalProps = {
    onClose?: () => void,
    open: boolean,
    children: React.ReactNode,
    isFullScreen?: boolean,
    extra?: string,
    id?: string
    outsideClickId?: string
}

const Modal = (props: ModalProps) => {
    const {
        open,
        onClose = () => {
        },
        children,
        isFullScreen = false,
        extra = ``,
        id,
        outsideClickId
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        onClose();
    }, outsideClickId)

    const modal = useTypedSelector(selectModal)

    useEffect(() => {
        let body = document.getElementsByTagName("body");
        if (modal) {
            body.item(0)!.style.overflow = "hidden";
        } else {
            body.item(0)!.style.overflow = "auto";
        }

    }, [open, modal])

    return (
        <>
            {open && <Portal id="modal-root">
                <ModalWrapper id={id} extra={extra}>
                    <Container isFullScreen={isFullScreen} ref={ref}>
                        {children}
                    </Container>
                </ModalWrapper>
            </Portal>}
        </>
    );
};

export default Modal;

const Container = styled.div<{ isFullScreen: boolean }>`
  ${({isFullScreen}) =>
          isFullScreen &&
          css`
            margin: 0;
            position: relative;
            //overflow-y: auto;
            display: flex;
            flex-direction: column;
            //max-height: none;
            //min-width: 1000px;
            //width: 100%;
            //height: 100%;
            width: min-content;
            height: min-content;
            border-radius: 0;
          `}
`

const ModalWrapper = styled.div<{ extra: string }>`
  padding: 0;
  z-index: 100000000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.7);

  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  ${({extra}) => extra}
`


