import React, {useRef, useEffect} from "react";

const useOutsideClick = (ref: any, handleClick: () => void, id?: string) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const elem = document.getElementById(id ?? '')

            if (elem) {
                if (ref.current && !ref.current.contains(event.target) && !elem.contains(event.target)) {
                    handleClick();
                }
            } else {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClick();
                }
            }

        }

        document.addEventListener("mousedown", handleClickOutside);
        //document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            //document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref, id]);
}


export default useOutsideClick
