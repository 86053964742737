import React from "react";
import styled from "styled-components";

export interface CustomTextProps {
    children: React.ReactNode,
    family?: string,
    color?: string,
    weight?: number,
    size?: number,
    height?: number,
    className?: any,
    style?: React.CSSProperties,
    onClick?: () => void,
    dangerouslySetInnerHTML?: { __html: string } | undefined,
    id?: string
    title?: string
}

interface ITextStyle {
    family?: string,
    color?: string,
    weight?: number,
    size?: number,
    height?: number,
}

const CustomText = ({
                        children,
                        family,
                        color,
                        weight,
                        size,
                        height,
                        className,
                        style,
                        onClick,
                        dangerouslySetInnerHTML,
                        id,
                        title
                    }: CustomTextProps): JSX.Element => {
    return (
        <Text family={family} height={height} size={size} weight={weight}
                 color={color}
                 className={className} style={style}
                 onClick={onClick} id={id} title={title}
                 dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
            {children}
        </Text>
    )
}

export default CustomText;

const Text = styled.p<ITextStyle>`
  margin: 0;
  font-family: ${({family = `'Roboto Condensed', 'Roboto', 'serif'`}) => family};
  font-weight: ${({weight = 400}) => weight};
  font-size: ${({size = 12}) => size}px;
  line-height: ${({height = 14}) => height}px;
  color: ${({color, theme}) => color ?? theme.palette.color.main};

  hyphens: auto;
  hyphenate-limit-chars: 6 3 2;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 2;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
`