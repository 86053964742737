import React from 'react';
import CustomText, {CustomTextProps} from "../index";

const HeaderOne = (props: CustomTextProps) => {
    return (
        <CustomText size={26} height={30} weight={700} {...props}>
            {props.children}
        </CustomText>
    );
};

export default HeaderOne;